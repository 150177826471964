import { RadioGroup } from '@headlessui/react'
import React from 'react'
import { ReactComponent as RadioEmpty } from '../../../assets/img/icons/radio-icons/radio-empty.svg'
import { ReactComponent as RadioFilled } from '../../../assets/img/icons/radio-icons/radio-filled.svg'
import DropdownList, { DropdownListItem } from '../../../components/dropdowns/DropdownList'
import { useLocalization } from '../../../hooks/useLocalization'
import { ESIMode } from '../../../model/eluent/ESIMode'
import { CalculationModelDTO } from '../../../model/solventData/CalculationModelDTO'
import CalculationCard from '../components/CalculationCard'

interface EsiModeProps {
    esiMode: ESIMode
    possibleEsiModes: ESIMode[]
    setEsiMode: (esiMode: ESIMode) => void
    availableCalculationModels: CalculationModelDTO[],
    currentPositiveModel: CalculationModelDTO | undefined,
    setPositiveModel: (model: CalculationModelDTO) => void,
    currentNegativeModel: CalculationModelDTO | undefined,
    setNegativeModel: (model: CalculationModelDTO) => void
}

function EsiModeCard(props: EsiModeProps) {

    const l10n = useLocalization().calculation.step1.esiMode

    function translateEsiMode(esiMode: ESIMode): string {
        switch (esiMode) {
            case 'positive':
                return l10n.options.positive

            case 'negative':
                return l10n.options.negative

            case 'dual':
                return l10n.options.dual
        }
    }
    
    function positiveModelsAsItems(): DropdownListItem[] {
        return props.availableCalculationModels
            .filter((model) => {
                return model.esiMode === 'positive'
            })
            .map( (model) => {
            return {
                title: model.displayName,
                object: model
            }
        })
    }

    function getSelectedPositiveModel(): DropdownListItem | undefined {
        const model = props.currentPositiveModel;
        if (model === undefined) { return undefined }
        return {
            title: model.displayName,
            object: model
        };
    }

    function setSelectedPositiveModel(selectedItem: DropdownListItem): void {
        const selectedModel = selectedItem.object as CalculationModelDTO;
        props.setPositiveModel(selectedModel);
    }
    
    function negativeModelsAsItems(): DropdownListItem[] {
        return props.availableCalculationModels
            .filter((model) => {
                return model.esiMode === 'negative'
            })
            .map( (model) => {
                return {
                    title: model.displayName,
                    object: model
                }
            })
    }

    function getSelectedNegativeModel(): DropdownListItem | undefined{
        const model = props.currentNegativeModel
        if (model === undefined) { return undefined }
        return {
            title: model.displayName,
            object: model
        }
    }

    function setSelectedNegativeModel(selectedItem: DropdownListItem): void {
        const selectedModel = selectedItem.object as CalculationModelDTO
        props.setNegativeModel(selectedModel)
    }

    function renderPositiveModelDropdown() {
        if (props.esiMode !== 'negative') {
            return (<div className="flex flex-col space-y-1 w-[250px] h-16">
                <span className="text-c1 font-semibold capitalize">{l10n.positiveModelTitle}</span>
                <DropdownList
                    className="text-b2 h-[40px]"
                    items={positiveModelsAsItems()}
                    title={l10n.positiveModelTitle}
                    getSelected={getSelectedPositiveModel}
                    setSelected={setSelectedPositiveModel}
                />
            </div>)
        }
    }

    function renderNegativeModelDropdown() {
        if (props.esiMode !== 'positive') {
            return (
            <div className="flex flex-col space-y-1 w-[250px] h-16">
                <span className="text-c1 font-semibold capitalize">{l10n.negativeModelTitle}</span>
                <DropdownList
                    className="text-b2 h-[40px]"
                    items={negativeModelsAsItems()}
                    title={l10n.negativeModelTitle}
                    getSelected={getSelectedNegativeModel}
                    setSelected={setSelectedNegativeModel}
                />
            </div>
            )
        }
    }

    return (
        <CalculationCard className="min-h-28 flex-grow" title={l10n.title}>
            <div className="flex flex-wrap space-x-8">
                <div className="text-b2">
                    <RadioGroup value={props.esiMode} onChange={props.setEsiMode}>
                        {props.possibleEsiModes.map((esiMode, key) => {
                            return (
                                <RadioGroup.Option value={esiMode} key={key}>
                                    {({checked}) => (
                                        <div className="mt-2 flex cursor-pointer">
                                        <span className="my-auto" aria-hidden="true">{checked ? <RadioFilled/> :
                                            <RadioEmpty/>}</span>
                                            <span
                                                className="my-auto ml-2 capitalize lowercase">{translateEsiMode(esiMode)}</span>
                                        </div>
                                    )}
                                </RadioGroup.Option>
                            )
                        })}
                    </RadioGroup>
                </div>
                <div className="mt-4 xl:pl-24 flex flex-wrap space-x-8">
                    {renderPositiveModelDropdown()}
                    {renderNegativeModelDropdown()}
                </div>
            </div>
        </CalculationCard>
)
}

export default EsiModeCard
