import React, { useEffect, useState } from 'react'
import MainLoadingIndicatorCard from '../../components/loadingIndicators/MainLoadingIndicatorCard'
import { useAuth } from '../../hooks/useAuth'
import { useLocalization } from '../../hooks/useLocalization'
import { Eluent } from '../../model/eluent/Eluent'
import { EluentTemplate } from '../../model/eluent/EluentTemplate'
import { ESIMode } from '../../model/eluent/ESIMode'
import { GradientStep } from '../../model/eluent/GradientStep'
import { IFileParseError } from '../../model/IFileParseError'
import { CalculationModelDTO } from '../../model/solventData/CalculationModelDTO'
import { User } from '../../model/User'
import { ConcentrationUnit } from '../../model/solventData/ConcentrationUnit'
import { IOrganicPhaseModifier } from '../../model/solventData/IOrganicPhaseModifier'
import { ISolventData } from '../../model/solventData/ISolventData'
import { IWaterPhaseAdditive } from '../../model/solventData/IWaterPhaseAdditive'
import {
    CalculationFileValidationErrorType,
    CalculationStartResponse,
    fetchSolventData,
    startCalculation
} from '../../providers/EluentDataProvider'
import { fetchGradientTemplates } from '../../providers/EluentTemplateProvider'
import { startCalculationProcessAndUploadFiles } from '../../services/CalculationService'
import CalculationStepDisplay from './CalculationStepDisplay'
import CalculationStep1 from './step1/CalculationStep1'
import CalculationStep2 from './step2/CalculationStep2'
import CalculationStep3 from './step3/CalculationStep3'

type TCalculationStep = 1 | 2 | 3 | 4;

function Calculation() {

    const [calculationStep, setCalculationStep] = useState<TCalculationStep>(1)
    const [hasFinishedLoading, setHasFinishedLoading] = useState(false)
    const [templates, setTemplates] = useState<EluentTemplate[]>([])
    const [selectedTemplate, setSelectedTemplate] = useState<EluentTemplate>()
    const [waterPhaseAdditives, setWaterPhaseAdditives] = useState<IWaterPhaseAdditive[]>([])
    const [organicPhaseModifiers, setOrganicPhaseModifiers] = useState<IOrganicPhaseModifier[]>([])
    const [concentrationUnits, setConcentrationUnits] = useState<ConcentrationUnit[]>([])
    const [currentWaterPhaseAdditive, setCurrentWaterPhaseAdditive] = useState<IWaterPhaseAdditive>()
    const [currentOrganicPhaseModifier, setCurrentOrganicPhaseModifier] = useState<IOrganicPhaseModifier>()
    const [currentConcentrationUnit, setCurrentConcentrationUnit] = useState<ConcentrationUnit>()
    const [availableCalculationModels, setAvailableCalculationModels] = useState<CalculationModelDTO[]>([])
    const [selectedPositiveModel, setSelectedPositiveModel] = useState<CalculationModelDTO>()
    const [selectedNegativeModel, setSelectedNegativeModel] = useState<CalculationModelDTO>()
    const [pHValue, setPHValue] = useState<number>(3.00)
    const [esiMode, setEsiMode] = useState<ESIMode>('positive')
    const [deadTime, setDeadTime] = useState<number>(0.0)
    const [gradientSteps, setGradientSteps] = useState<GradientStep[]>([{
        startTime: 0,
        organicPhaseModifierPercent: 0
    }])
    const [selectedFiles, setSelectedFiles] = useState<File[]>([])
    const [isProcessingFiles, setIsProcessingFiles] = useState(false)
    const [calculationCode, setCalculationCode] = useState<string>()
    const [calculationFinishedError, setCalculationFinishedError] = useState<Error>()
    const errorL10n = useLocalization().calculation.step2.error
    const auth = useAuth()

    useEffect(() => {
        Promise.all([fetchGradientTemplates(), fetchSolventData()])
            .then((results) => {
                const templates = results[0]
                const eluentData = results[1]

                applyFetchedEluentDataToState(eluentData)
                applyFetchedTemplatesToState(templates, eluentData)
            })
            .finally(() => {
                setHasFinishedLoading(true)
            })
    }, [auth])

    function applyFetchedTemplatesToState(templates: EluentTemplate[], solventData: ISolventData) {
        setTemplates(templates)
        const defaultTemplate = templates.find((template) => template.isDefault)
        if (defaultTemplate !== undefined) {
            applyTemplateToState(
                defaultTemplate,
                solventData.waterPhaseAdditives,
                solventData.organicPhaseModifiers,
                solventData.concentrationUnits
            )
        }
    }

    function applyFetchedEluentDataToState(solventData: ISolventData) {
        setWaterPhaseAdditives(solventData.waterPhaseAdditives)
        setOrganicPhaseModifiers(solventData.organicPhaseModifiers)
        setConcentrationUnits(solventData.concentrationUnits)
        setSelectedPositiveModel(
            solventData.models.filter(
                (model) => model.esiMode === 'positive' && model.version.startsWith("universal")
            )[0]
        );

        setSelectedNegativeModel(
            solventData.models.filter(
                (model) => model.esiMode === 'negative' && model.version.startsWith('universal')
            )[0]
        );
        setAvailableCalculationModels(solventData.models)

        const formicAcid = solventData.waterPhaseAdditives.find((additive) => additive.value === 'FORMIC_ACID')
        setCurrentWaterPhaseAdditive(formicAcid ?? solventData.waterPhaseAdditives.find(() => true))
        setCurrentOrganicPhaseModifier(solventData.organicPhaseModifiers.find(() => true))
        setCurrentConcentrationUnit(solventData.concentrationUnits.find(() => true))
        if (solventData.organicPhaseModifiers.length > 0) {
            setCurrentOrganicPhaseModifier(solventData.organicPhaseModifiers[0])
        }
    }

    function applyTemplateToState(
        template: EluentTemplate,
        waterPhaseAdditives: IWaterPhaseAdditive[],
        organicPhaseModifiers: IOrganicPhaseModifier[],
        concentrationUnits: ConcentrationUnit[]
    ) {
        setSelectedTemplate(template)
        setEsiMode(template.eluent.esiMode)
        setDeadTime(template.eluent.deadTime)
        setPHValue(template.eluent.ph)
        setGradientSteps(template.eluent.steps)

        const positiveModel = template.eluent.modelInfo?.find((model) => model.esiMode === 'positive')
        const negativeModel = template.eluent.modelInfo?.find((model) => model.esiMode === 'negative')

        if (positiveModel !== undefined) {
            setSelectedPositiveModel(positiveModel)
        }

        if (negativeModel !== undefined) {
            setSelectedNegativeModel(negativeModel)
        }

        const templateWaterPhase = waterPhaseAdditives.find((additive) => {
            return template.eluent.waterPhaseAdditiveValue === additive.value
        })

        const templateOrganicPhase = organicPhaseModifiers.find((modifier) => {
            return template.eluent.organicPhaseModifierValue === modifier.value
        })

        const templateConcentrationUnit = concentrationUnits.find((unit) => {
            return template.eluent.concentrationUnitValue === unit.value
        })

        setCurrentWaterPhaseAdditive(templateWaterPhase)
        setCurrentOrganicPhaseModifier(templateOrganicPhase)
        setCurrentConcentrationUnit(templateConcentrationUnit)
    }

    function triggerProcessingFiles(fileParseErrorHandler: (error: IFileParseError) => void, errorHandler: (message: string) => void) {
        setIsProcessingFiles(true)
        uploadSelectedFiles(selectedFiles, fileParseErrorHandler, errorHandler)
    }

    function getCalculationModelsForCurrentState(): CalculationModelDTO[] {
        if (esiMode === 'positive') {
            if (selectedNegativeModel === undefined) {
                return []
            }
            return [selectedPositiveModel!]
        }

        if (esiMode === 'negative') {
            if (selectedPositiveModel === undefined) {
                return []
            }
            return [selectedNegativeModel!]
        }

        if (selectedPositiveModel === undefined || selectedNegativeModel === undefined) {
            return []
        }

        return [selectedPositiveModel, selectedNegativeModel]
    }

    function uploadSelectedFiles(files: File[], fileParseErrorHandler: (error: IFileParseError) => void, errorHandler: (message: string) => void) {
        const eluentData: Eluent = {
            esiMode: esiMode,
            organicPhaseModifierValue: currentOrganicPhaseModifier?.value ?? '',
            waterPhaseAdditiveValue: currentWaterPhaseAdditive?.value ?? '',
            concentrationUnitValue: currentConcentrationUnit?.value ?? '',
            ph: pHValue,
            deadTime: deadTime,
            modelInfo: getCalculationModelsForCurrentState(),
            steps: gradientSteps
        }

        if (isSelectedTemplate(eluentData)) {
            eluentData.id = selectedTemplate?.eluent.id
        }

        startCalculationProcessAndUploadFiles(files)
            .then((data) => {
                return {
                    fileIds: data.map(file => file.fileId),
                    eluent: eluentData,
                    models: getCalculationModelsForCurrentState()
                }
            })
            .then((data) => {
                return startCalculation(data)
            })
            .then(response => {
                if (response.didStart) {
                    setCalculationCode(response.calculationCode)
                    setCalculationStep(3)
                } else {
                    handleInvalidFiles(response, fileParseErrorHandler)
                }
            })
            .catch((error) => {
                if (error.isAxiosError !== undefined && error.isAxiosError) {
                    if (error.response.status !== undefined && error.response.status === 403) {
                        errorHandler(errorL10n.quotaExceeded)
                    } else {
                        errorHandler(errorL10n.genericError)
                    }
                } else {
                    errorHandler(errorL10n.genericError)
                }
            })
            .finally(() => setIsProcessingFiles(false))
    }

    function handleInvalidFiles(response: CalculationStartResponse, errorHandler: (error: IFileParseError) => void) {
        response.validations.map(validation => {
            validation.validationErrors.map(error => {
                switch (error.type) {
                    case CalculationFileValidationErrorType.FAILED_ROW_COUNT:
                        errorHandler({
                            filename: error.fileName,
                            error: errorL10n.parseError
                        })
                        break

                    case CalculationFileValidationErrorType.EXCEEDED_ROW_COUNT:
                        errorHandler({
                            filename: error.fileName,
                            error: errorL10n.exceededRowCount
                        })
                        break

                    case CalculationFileValidationErrorType.INVALID_SMILES:
                        errorHandler({
                            filename: error.fileName,
                            error: `${errorL10n.invalidSmilesInFile} ${error.additionalInfo}`
                        })
                        break

                    case CalculationFileValidationErrorType.NOT_ENOUGH_TAILORING_COMPOUNDS:
                        errorHandler({
                            filename: error.fileName,
                            error: errorL10n.notEnoughTailoringCompounds
                        })
                        break

                    case CalculationFileValidationErrorType.INVALID_STRUCTURE:
                        errorHandler({
                            filename: error.fileName,
                            error: `${errorL10n.incorrectStructure}`
                        })
                        break

                    case CalculationFileValidationErrorType.RET_TIME_NEGATIVE:
                        errorHandler({
                            filename: error.fileName,
                            error: `${errorL10n.retTimeNegativeError}`
                        })
                        break

                    case CalculationFileValidationErrorType.RET_TIME_MISSING_OR_NAN:
                        errorHandler({
                            filename: error.fileName,
                            error: `${errorL10n.retTimeMissingError}`
                        })
                        break

                    case CalculationFileValidationErrorType.SIGNAL_NEGATIVE:
                        errorHandler({
                            filename: error.fileName,
                            error: `${errorL10n.signalMissingError}`
                        })
                        break

                    case CalculationFileValidationErrorType.SIGNAL_MISSING_OR_NAN:
                        errorHandler({
                            filename: error.fileName,
                            error: `${errorL10n.signalMissingError}`
                        })
                        break

                    case CalculationFileValidationErrorType.CONCENTRATION_NEGATIVE:
                        errorHandler({
                            filename: error.fileName,
                            error: `${errorL10n.concentrationNegativeError}`
                        })
                        break

                    case CalculationFileValidationErrorType.ION_MODE_MISSING:
                        errorHandler({
                            filename: error.fileName,
                            error: `${errorL10n.esiModeMissingError}`
                        })
                        break

                    case CalculationFileValidationErrorType.ION_MODE_INVALID:
                        errorHandler({
                            filename: error.fileName,
                            error: `${errorL10n.esiModeInvalidError}`
                        })
                        break

                    case CalculationFileValidationErrorType.SINGLE_MODE_ION_MODE_CONFLICT:
                        errorHandler({
                            filename: error.fileName,
                            error: errorL10n.singleModeIonModeConflictError
                        })
                        break

                    default:
                        errorHandler({
                            filename: error.fileName,
                            error: `${errorL10n.genericError}`
                        })
                        break
                }
            })
        })
    }

    function isSelectedTemplate(eluent: Eluent): boolean {
        return eluent.steps === selectedTemplate?.eluent.steps
            && eluent.ph === selectedTemplate.eluent.ph
            && eluent.esiMode === selectedTemplate.eluent.esiMode
            && eluent.organicPhaseModifierValue === selectedTemplate.eluent.organicPhaseModifierValue
            && eluent.waterPhaseAdditiveValue === selectedTemplate.eluent.waterPhaseAdditiveValue
            && eluent.concentrationUnitValue === selectedTemplate.eluent.concentrationUnitValue
    }

    function renderLoading() {
        return <MainLoadingIndicatorCard className="h-[700px]"/>
    }

    function renderContent(user: User) {
        switch (calculationStep) {
            case 1:
                return (
                    <CalculationStep1
                        user={user}
                        templates={templates}
                        setTemplates={setTemplates}
                        selectedTemplate={selectedTemplate}
                        setSelectedTemplate={(template) => {
                            applyTemplateToState(template, waterPhaseAdditives, organicPhaseModifiers, concentrationUnits)
                        }}
                        esiMode={esiMode}
                        setEsiMode={setEsiMode}
                        availableCalculationModels={availableCalculationModels}
                        currentPositiveModel={selectedPositiveModel}
                        setPositiveModel={setSelectedPositiveModel}
                        currentNegativeModel={selectedNegativeModel}
                        setNegativeModel={setSelectedNegativeModel}
                        waterPhaseAdditives={waterPhaseAdditives}
                        organicPhaseModifiers={organicPhaseModifiers}
                        concentrationUnits={concentrationUnits}
                        currentWaterPhaseAdditive={currentWaterPhaseAdditive}
                        currentOrganicPhaseModifier={currentOrganicPhaseModifier}
                        currentConcentrationUnit={currentConcentrationUnit}
                        setCurrentWaterPhaseAdditive={setCurrentWaterPhaseAdditive}
                        setCurrentOrganicPhaseModifier={setCurrentOrganicPhaseModifier}
                        setCurrentConcentrationUnit={setCurrentConcentrationUnit}
                        pHValue={pHValue}
                        setPHValue={setPHValue}
                        deadTime={deadTime}
                        setDeadTime={setDeadTime}
                        gradientSteps={gradientSteps}
                        setGradientSteps={setGradientSteps}
                        nextClicked={() => {
                            setCalculationStep(2)
                        }}
                    />
                )
            case 2:
                return (
                    <CalculationStep2
                        canProceed={selectedFiles.length > 0}
                        isProcessingFiles={isProcessingFiles}
                        selectedFiles={selectedFiles}
                        esiMode={esiMode}
                        setSelectedFiles={setSelectedFiles}
                        backButtonClicked={() => {
                            setCalculationStep(1)
                        }}
                        nextButtonClicked={(fileParseErrorHandler: (error: IFileParseError) => void, errorHandler: (message: string) => void) => {
                            triggerProcessingFiles(fileParseErrorHandler, errorHandler)
                        }}
                    />
                )
            case 3:
            case 4:
                return (
                    <CalculationStep3
                        calculationCode={calculationCode!}
                        calculationFinishHandler={(error) => {
                            setCalculationFinishedError(error)
                            setCalculationStep(4)
                        }}
                        hasFinished={calculationStep === 4}
                        finishedWithError={calculationFinishedError}
                    />
                )
        }
        return
    }

    return auth.user !== undefined
        ? (
            <div className="w-full h-full flex flex-col space-y-4 pt-4">
                <CalculationStepDisplay currentStep={calculationStep}/>
                <div className="flex-grow">
                    {hasFinishedLoading ? renderContent(auth.user) : renderLoading()}
                </div>
            </div>
        )
        : (<div/>)
}

export default Calculation
