import { Modal, Table } from 'flowbite-react'
import React, { useState } from 'react'
import { ReactComponent as CheckmarkIcon } from '../../../assets/img/icons/checkmark-icon.svg'
import { ReactComponent as TrashIcon } from '../../../assets/img/icons/trash-icon-primary.svg'
import IconButton from '../../../components/buttons/IconButton'
import ButtonPrimaryOutline from '../../../components/buttons/primary/ButtonPrimaryOutline'
import DropdownList, { DropdownListItem } from '../../../components/dropdowns/DropdownList'
import QuantemScaleLoader from '../../../components/loadingIndicators/QuantemScaleLoader'
import { useLocalization } from '../../../hooks/useLocalization'
import { OrganizationListItemDto, OrganizationTier } from '../../../model/admin/OrganizationListItemDto'
import { deleteOrganizationListItem, updateOrganization } from '../../../providers/AdminProvider'

interface AdminOrganizationRowProps {
    organization: OrganizationListItemDto
    tiers: OrganizationTier[]
    updateOrganization: (listItem: OrganizationListItemDto) => void
    deleteOrganization: (listItem: OrganizationListItemDto) => void
}

function AdminOrganizationRow(props: AdminOrganizationRowProps) {
    const sharedStyle = 'text-c1 font-semibold text-textDark-100'
    const l10n = useLocalization().admin.organizations
    const [name, setName] = useState<string>(props.organization.name)
    const [tier, setTier] = useState(props.organization.tier)
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)

    function mapTiersToDropwdownItems(tiers: OrganizationTier[]): DropdownListItem[] {
        return tiers.map((tier): DropdownListItem => {
            return {
                title: tier.id,
                object: tier
            }
        })
    }

    function getSelected() {
        return {
            title: tier.id,
            object: tier
        }
    }

    function setSelected(object: DropdownListItem) {
        setTier(object.object)
    }

    function canSave(): boolean {
        return props.organization.tier.id !== tier.id || props.organization.name !== name
    }

    function saveOrganization() {
        const modifiedOrg: OrganizationListItemDto = {
            id: props.organization.id,
            name: name,
            userCount: props.organization.userCount,
            calculationsDone: props.organization.calculationsDone,
            tier: tier,
            modelPrivileges: props.organization.modelPrivileges
        }
        setIsSaving(true)
        updateOrganization(modifiedOrg)
            .then(props.updateOrganization)
            .catch(window.alert)
            .finally(() => setIsSaving(false))
    }

    function deleteOrganization() {
        setIsDeleting(true)
        deleteOrganizationListItem(props.organization)
            .then(() => {
                props.deleteOrganization(props.organization)
            })
            .catch(window.alert)
            .finally(() => setIsDeleting(false))
    }

    function compileModal(): JSX.Element {
        return <Modal show={showDeleteConfirm} onClose={() => setShowDeleteConfirm(false)}>
            <Modal.Header className="text-error-500">{l10n.deleteConfirm}</Modal.Header>
            <Modal.Body>
                <div className="space-y-6">
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                        {l10n.deleteConfirmExplanation}
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ButtonPrimaryOutline
                    className="border-error-500 text-textLight-100 bg-error-500 hover:bg-error-200 h-[40px] w-[150px]"
                    text={l10n.delete}
                    onClick={() => {
                        setShowDeleteConfirm(false)
                        deleteOrganization()
                    }
                    }
                />
                <ButtonPrimaryOutline
                    className="h-[40px] w-[150px]"
                    text={l10n.cancel}
                    onClick={() => setShowDeleteConfirm(false)}
                />
            </Modal.Footer>
        </Modal>
    }

    function compileRow() {
        return <Table.Row className="bg-white shadow dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className={sharedStyle}>
                <span className={sharedStyle}>{props.organization.id}</span>
            </Table.Cell>
            <Table.Cell className={`${sharedStyle} cursor-pointer`}>
                <input
                    className="min-w-[350px] h-[40px] text-c1 border-none rounded-md hover:border-background-light-100 p-2"
                    type="text"
                    lang="en-US"
                    value={name}
                    onChange={event => setName(event.target.value)}
                />
            </Table.Cell>
            <Table.Cell className={sharedStyle}>
                <span className={sharedStyle}>{props.organization.userCount}</span>
            </Table.Cell>
            <Table.Cell className={sharedStyle}>
                <span className={sharedStyle}>{props.organization.calculationsDone}</span>
            </Table.Cell>
            <Table.Cell className={sharedStyle}>
                <span
                    className={sharedStyle}>{props.organization.tier.calculationLimit - props.organization.calculationsDone}</span>
            </Table.Cell>
            <Table.Cell className={sharedStyle}>
                <DropdownList
                    className="text-c1 w-[175px] h-[40px]"
                    items={mapTiersToDropwdownItems(props.tiers)}
                    title={tier.id}
                    getSelected={getSelected}
                    setSelected={setSelected}
                />
            </Table.Cell>
            <Table.Cell className={`${sharedStyle} flex flex-row`}>
                <div className="scale-75">
                    <IconButton
                        className="bg-primary-700 hover:bg-primary-400 disabled:bg-primary-100"
                        isDisabled={!canSave()}
                        onClick={() => {
                            saveOrganization()
                        }}>
                        {
                            isSaving
                                ? <div className="flex scale-50"><QuantemScaleLoader colorOverride="#FFFFFF"/></div>
                                : <CheckmarkIcon className="fill-backgroundLight-100"/>
                        }

                    </IconButton>
                </div>
                <div className="scale-75">

                    <IconButton
                        className="bg-error-700 hover:bg-error-400 disabled:bg-error-50"
                        isDisabled={props.organization.userCount > 0}
                        onClick={() => {
                            setShowDeleteConfirm(true)
                        }}>
                        {isDeleting
                            ? <div className="flex scale-50"><QuantemScaleLoader colorOverride="#FFFFFF"/></div>
                            : <TrashIcon className="fill-textLight-100"/>
                        }
                    </IconButton>
                </div>
            </Table.Cell>
        </Table.Row>
    }

    return (
        <>
            {compileModal()}
            {compileRow()}
        </>
    )
}

export default AdminOrganizationRow
