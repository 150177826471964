import React, { useRef, useState } from 'react'
import { FileDrop } from 'react-file-drop'
import { ReactComponent as CrossIcon } from '../../../assets/img/icons/cross-icon.svg'
import { ReactComponent as DocumentIcon } from '../../../assets/img/icons/document-icon.svg'
import { ReactComponent as ErrorIcon } from '../../../assets/img/icons/error-icon.svg'
import { ReactComponent as LeftArrowPrimary } from '../../../assets/img/icons/left-arrow-primary.svg'
import { ReactComponent as RightArrowDisabled } from '../../../assets/img/icons/right-arrow-disabled.svg'
import { ReactComponent as RightArrowPrimary } from '../../../assets/img/icons/right-arrow-primary.svg'
import { ReactComponent as TrashIconPrimary } from '../../../assets/img/icons/trash-icon-primary.svg'
import { ReactComponent as UploadCloudIconPrimary } from '../../../assets/img/icons/upload-cloud-icon-primary.svg'
import { ReactComponent as UploadCloudIcon } from '../../../assets/img/icons/upload-cloud-icon.svg'
import ButtonPrimaryOutline from '../../../components/buttons/primary/ButtonPrimaryOutline'
import { useLocalization } from '../../../hooks/useLocalization'
import { ESIMode } from '../../../model/eluent/ESIMode'
import { IFileParseError } from '../../../model/IFileParseError'
import { fetchExampleFile } from '../../../providers/AnalytesProvider'

interface ICalculationStep2Props {
    canProceed: boolean;
    isProcessingFiles: boolean;
    selectedFiles: File[];
    esiMode: ESIMode
    setSelectedFiles: (files: File[]) => void;
    backButtonClicked: () => void;
    nextButtonClicked: (fileParseErrorHandler: (error: IFileParseError) => void, errorHandler: (errorMessage: string) => void) => void;
}

interface DisplayableError {
    title: string
    message: string
}

function CalculationStep2(props: ICalculationStep2Props) {

    const l10n = useLocalization().calculation.step2
    const fileInputRef = useRef<HTMLInputElement>(null)
    const [isHoveringOverPicker, setIsHoveringOverPicker] = useState(false)
    const [errors, setErrors] = useState<DisplayableError[]>([])

    function renderFileDropContent() {
        if (props.selectedFiles.length < 1) {
            return (
                <div className="flex w-full h-full">
                    <div className="flex flex-col m-auto w-max items-center space-y-4">
                        <span><UploadCloudIcon/></span>
                        <div>
                            <span className="text-c1 font-semibold text-textDark-100">{l10n.dragAndDropLabel}</span>
                            <span className="text-c1 text-primary-600 cursor-pointer" onClick={(event) => {
                                event.preventDefault()
                                fileInputRef.current?.click()
                            }}>{l10n.browseLabel}</span>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="flex w-full h-full">
                    <div className="flex flex-col py-2">
                        {props.selectedFiles.map(renderFileRow)}
                    </div>
                </div>
            )
        }
    }

    function renderFileRow(file: File, index: number): JSX.Element {
        return (
            <div key={index} className="flex border border-division rounded h-8 p-2 space-x-4 mx-2 my-1">
                <span className="my-auto"><DocumentIcon/></span>
                <div className="flex-grow">
                    <p className="flex">
                        <span className="text-b2 text-textDark-100 font-semibold my-auto">{file.name}</span>
                    </p>
                </div>
                <span className="my-auto cursor-pointer" onClick={(event) => {
                    event.preventDefault()
                    removeFile(file)
                }}><CrossIcon/></span>
            </div>
        )
    }

    function handleFileParseError(error: IFileParseError) {
        const displayableError = {
            title: `${l10n.error.calculationErrorIn} ${error.filename}`,
            message: error.error
        }
        const newErrors = [...errors, displayableError]
        setErrors([...newErrors])
    }

    function handleGenericError(errorMessage: string) {
        const displayableError = {
            title: l10n.error.genericCalculationFailedMessage,
            message: errorMessage
        }
        const newErrors = [...errors, displayableError]
        setErrors([...newErrors])
    }

    function addError(file: File, errorText: string) {
        const error: IFileParseError = {
            filename: file.name,
            error: errorText
        }

        handleFileParseError(error)
    }

    function handleNewFiles(files: FileList | null) {
        setErrors([])
        if (files === null) {
            return
        }
        const newFiles = Array.from(files).filter((file) => {
            if (!file.name.endsWith('.csv') && !file.name.endsWith('.xlsx')) {
                addError(file, l10n.error.incorrectFormat)
                return false
            }

            if (file.size > 10 * 1024 * 1024) {
                addError(file, l10n.error.tooLarge)
                return false
            }

            return true
        })
        const joined = newFiles.concat(props.selectedFiles)
        props.setSelectedFiles([...joined])
    }

    function removeFile(file: File) {
        setErrors([])
        const index = props.selectedFiles.findIndex((f) => f === file)
        const files = props.selectedFiles
        files.splice(index, 1)
        props.setSelectedFiles([...files])
    }

    function clearFiles() {
        setErrors([])
        props.setSelectedFiles([])
    }

    function downloadExampleFile(esiMode: ESIMode) {
        fetchExampleFile(esiMode)
            .then(url => {
                window.location.href = url
            })
    }

    function renderErrors() {
        return errors.map((error, index) => {
            return (
                <div key={index} className="bg-error-50 border border-error-200 rounded flex h-20">
                    <span className="ml-4 mt-4"><ErrorIcon/></span>
                    <div className="flex flex-col mx-4 my-2.5 space-y">
                        <span className="text-b1 font-bold">{error.title}</span>
                        <span className="text-b2 font-normal text-textDark-200">{error.message}</span>
                    </div>
                </div>
            )
        })
    }

    return (
        <div className="flex flex-col mt-14 mx-2 space-y-2.5">
            <div className="flex h-9 space-x-2">
                <ButtonPrimaryOutline
                    className="text-c1 w-[150px]"
                    text={l10n.addFiles}
                    iconRight={<UploadCloudIconPrimary/>}
                    onClick={(event) => {
                        event.preventDefault()
                        fileInputRef.current?.click()
                    }}
                />
                <ButtonPrimaryOutline
                    className={`${props.selectedFiles.length === 0 ? 'hidden' : ''} text-c1 w-[150px]`}
                    text={l10n.removeAll}
                    iconRight={<TrashIconPrimary/>}
                    onClick={(event) => {
                        event.preventDefault()
                        clearFiles()
                    }}
                />
            </div>
            <FileDrop
                className={`${isHoveringOverPicker
                    ? 'bg-backgroundLight-300 border-solid border-primary-800'
                    : 'bg-backgroundLight-100 border-dashed border-primary-100'} flex  border rounded-lg min-h-80`}
                targetClassName="border-solid bg border-primary-600 w-full"
                onDrop={(files) => {
                    handleNewFiles(files)
                    setIsHoveringOverPicker(false)
                }}
                onDragOver={() => {
                    setIsHoveringOverPicker(true)
                }}
                onDragLeave={() => {
                    setIsHoveringOverPicker(false)
                }}
            >
                {renderFileDropContent()}
            </FileDrop>
            <div>
                <span className="text-c1 text-textDark-200">{l10n.fileSizeFormatLabel} </span>
                <span className="text-c1 text-primary-600 cursor-pointer" onClick={(event) => {
                    event.preventDefault()
                    downloadExampleFile('positive')
                }}>
                    {l10n.singleFilePrompt}
                </span>
                <span className="text-c1 text-textDark-200"> {l10n.orLabel} </span>
                <span className="text-c1 text-primary-600 cursor-pointer" onClick={(event) => {
                    event.preventDefault()
                    downloadExampleFile('dual')
                }}>
                    {l10n.alternatingFilePrompt}
                </span>
            </div>
            <div className="mt-12 h-[40px] flex justify-between">
                <ButtonPrimaryOutline
                    className="text-b2 w-[150px]"
                    text={l10n.back}
                    iconLeft={<LeftArrowPrimary/>}
                    onClick={(event) => {
                        event.preventDefault()
                        props.backButtonClicked()
                    }}
                />
                <ButtonPrimaryOutline
                    className="text-b2 w-[150px]"
                    text={l10n.calculate}
                    isLoading={props.isProcessingFiles}
                    isDisabled={!props.canProceed}
                    iconRight={props.canProceed ? <RightArrowPrimary/> : <RightArrowDisabled/>}
                    onClick={(event) => {
                        event.preventDefault()
                        if (props.isProcessingFiles) {
                            return
                        }
                        props.nextButtonClicked(handleFileParseError, handleGenericError)
                    }}
                />
            </div>
            <div className="flex flex-col space-y-4">
                {renderErrors()}
            </div>
            <input
                className="hidden"
                type="file"
                ref={fileInputRef}
                multiple
                onChange={(event) => {
                    const {files} = event.target
                    handleNewFiles(files)
                }}
            />
        </div>
    )
}

export default CalculationStep2
