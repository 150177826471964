import React, { useState } from 'react'
import { ReactComponent as RightArrowPrimary } from '../../../assets/img/icons/right-arrow-primary.svg'
import ButtonPrimaryOutline from '../../../components/buttons/primary/ButtonPrimaryOutline'
import { useLocalization } from '../../../hooks/useLocalization'
import { Eluent } from '../../../model/eluent/Eluent'
import { EluentTemplate } from '../../../model/eluent/EluentTemplate'
import { ESIMode } from '../../../model/eluent/ESIMode'
import { GradientStep } from '../../../model/eluent/GradientStep'
import { CalculationModelDTO } from '../../../model/solventData/CalculationModelDTO'
import { User } from '../../../model/User'
import { ConcentrationUnit } from '../../../model/solventData/ConcentrationUnit'
import { IOrganicPhaseModifier } from '../../../model/solventData/IOrganicPhaseModifier'
import { IWaterPhaseAdditive } from '../../../model/solventData/IWaterPhaseAdditive'
import { deleteEluentTemplate, saveNewEluentTemplate } from '../../../providers/EluentTemplateProvider'
import AnalysisConditionsCard from './AnalysisConditionsCard'
import { ConcentrationUnitCard } from './ConcentrationUnitCard'
import DuplicateTemplateDialog from './dialogs/DuplicateTemplateDialog'
import SaveConditionsDialog from './dialogs/SaveConditionsDialog'
import EsiModeCard from './EsiModeCard'
import GradientCard from './gradient/GradientCard'
import SolventCard from './SolventCard'

interface CalculationStep1Props {
    user: User;
    templates: EluentTemplate[];
    setTemplates: (templates: EluentTemplate[]) => void;
    selectedTemplate?: EluentTemplate;
    setSelectedTemplate: (template: EluentTemplate) => void;
    esiMode: ESIMode;
    setEsiMode: (esiMode: ESIMode) => void;
    availableCalculationModels: CalculationModelDTO[],
    currentPositiveModel: CalculationModelDTO | undefined,
    setPositiveModel: (model: CalculationModelDTO) => void,
    currentNegativeModel: CalculationModelDTO | undefined,
    setNegativeModel: (model: CalculationModelDTO) => void
    waterPhaseAdditives: IWaterPhaseAdditive[];
    organicPhaseModifiers: IOrganicPhaseModifier[];
    currentWaterPhaseAdditive?: IWaterPhaseAdditive;
    currentOrganicPhaseModifier?: IOrganicPhaseModifier;
    setCurrentWaterPhaseAdditive: (additive: IWaterPhaseAdditive) => void;
    setCurrentOrganicPhaseModifier: (modifier: IOrganicPhaseModifier) => void;
    concentrationUnits: ConcentrationUnit[]
    currentConcentrationUnit?: ConcentrationUnit
    setCurrentConcentrationUnit: (selectedUnit: ConcentrationUnit) => void
    pHValue?: number;
    setPHValue: (value: number) => void;
    deadTime: number;
    setDeadTime: (value: number) => void;
    gradientSteps: GradientStep[],
    setGradientSteps: (steps: GradientStep[]) => void;
    nextClicked: () => void;
}

function CalculationStep1(props: CalculationStep1Props) {
    const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false)
    const [isDuplicateDialogOpen, setIsDuplicateDialogOpen] = useState(false)
    const [duplicateTemplateId, setDuplicateTemplateId] = useState<number>()
    const [duplicateTemplateName, setDuplicateTemplateName] = useState('')
    const l10n = useLocalization().calculation.step1

    function saveTemplate(name: string) {
        setDuplicateTemplateId(undefined)
        setDuplicateTemplateName('')
        const models = [props.currentPositiveModel, props.currentNegativeModel]
                .filter(model => model !== undefined) as CalculationModelDTO[]

        const eluent: Eluent = {
            esiMode: props.esiMode,
            organicPhaseModifierValue: props.currentOrganicPhaseModifier!.value,
            waterPhaseAdditiveValue: props.currentWaterPhaseAdditive!.value,
            concentrationUnitValue: props.currentConcentrationUnit!.value,
            ph: props.pHValue!,
            deadTime: props.deadTime,
            modelInfo: models,
            steps: props.gradientSteps
        }
        const template: EluentTemplate = {
            eluent: eluent,
            name: name,
            isDefault: props.templates.length === 0
        }

        saveNewEluentTemplate(template)
            .then((template) => {
                const templates = props.templates.filter(template => template.name.trim().toLowerCase() !== name.toLowerCase())
                props.setTemplates([...templates, template])
                props.setSelectedTemplate(template)
            })
    }

    function overwriteTemplateWithName(id: number, name: string) {
        deleteEluentTemplate(id)
            .then(() => {
                const templates = props.templates.filter(template => template.name.trim().toLowerCase() !== name.toLowerCase())
                props.setTemplates([...templates])
            })
            .then(() => saveTemplate(name))
    }

    function attemptToSaveTemplate(newName: string) {
        const foundTemplate = props.templates.find((template) => template.name.trim().toLowerCase() === newName.toLowerCase())
        if (foundTemplate !== undefined) {
            setDuplicateTemplateId(foundTemplate.id)
            setDuplicateTemplateName(newName)
            setIsDuplicateDialogOpen(true)
        } else {
            saveTemplate(newName)
        }
    }

    function canSaveTemplates(): boolean {
        return props.currentWaterPhaseAdditive !== undefined
            && props.currentOrganicPhaseModifier !== undefined
            && (props.pHValue !== undefined && !isNaN(props.pHValue))
            && props.gradientSteps.length > 0
    }

    function drawTemplateRow() {
        return (
            <>
                <SaveConditionsDialog
                    isOpen={isSaveDialogOpen}
                    closeModal={() => {
                        setIsSaveDialogOpen(false)
                    }}
                    saveClicked={(newName) => {
                        setIsSaveDialogOpen(false)
                        attemptToSaveTemplate(newName)
                    }}
                />
                <DuplicateTemplateDialog
                    isOpen={isDuplicateDialogOpen}
                    id={duplicateTemplateId!}
                    title={duplicateTemplateName}
                    closeModal={() => {
                        setIsDuplicateDialogOpen(false)
                        setDuplicateTemplateName('')
                        setDuplicateTemplateId(undefined)
                    }}
                    saveClicked={(id, name) => {
                        setIsDuplicateDialogOpen(false)
                        overwriteTemplateWithName(id, name)
                    }}
                />
                <AnalysisConditionsCard
                    templates={props.templates}
                    canSave={canSaveTemplates()}
                    setSelectedTemplate={props.setSelectedTemplate}
                    getSelectedTemplate={() => props.selectedTemplate}
                    saveClicked={() => {
                        setIsSaveDialogOpen(true)
                    }}
                />
            </>
        )
    }

    return (
        <div className="flex flex-col space-y-4 md:min-h-[600px] flex-grow">
            {drawTemplateRow()}
            <ConcentrationUnitCard concentrationUnits={props.concentrationUnits}
                                   selectedConcentrationUnit={props.currentConcentrationUnit}
                                   setCurrentConcentrationUnit={props.setCurrentConcentrationUnit}/>
            <EsiModeCard
                esiMode={props.esiMode}
                setEsiMode={props.setEsiMode}
                possibleEsiModes={['positive', 'negative', 'dual']}
                availableCalculationModels={props.availableCalculationModels}
                currentPositiveModel={props.currentPositiveModel}
                setPositiveModel={props.setPositiveModel}
                currentNegativeModel={props.currentNegativeModel}
                setNegativeModel={props.setNegativeModel}
            />
            <SolventCard
                waterPhaseAdditives={props.waterPhaseAdditives}
                organicPhaseModifiers={props.organicPhaseModifiers}
                currentWaterPhaseAdditive={props.currentWaterPhaseAdditive}
                currentOrganicPhaseModifier={props.currentOrganicPhaseModifier}
                setWaterPhaseAdditive={props.setCurrentWaterPhaseAdditive}
                setOrganicPhaseModifier={props.setCurrentOrganicPhaseModifier}
                currentPHValue={props.pHValue}
                setCurrentPHValue={props.setPHValue}
            />
            <GradientCard
                deadTime={props.deadTime}
                setDeadTime={props.setDeadTime}
                gradientSteps={props.gradientSteps}
                setGradientSteps={(steps) => props.setGradientSteps([...steps])}
            />
            <div className="mx-2 flex">
                <ButtonPrimaryOutline
                    className="ml-auto text-b2 h-[40px] w-[150px]"
                    text={l10n.next}
                    iconRight={<RightArrowPrimary/>}
                    onClick={(event) => {
                        event.preventDefault()
                        props.nextClicked()
                    }}
                />
            </div>
        </div>
    )
}

export default CalculationStep1
