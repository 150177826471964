import { useLocalization } from '../../hooks/useLocalization'
import { Eluent } from '../../model/eluent/Eluent'
import { ModelInfo } from '../../model/eluent/ModelInfo'
import { ISolventData } from '../../model/solventData/ISolventData'

interface IHistoryRowExpansionProps {
    eluentData: Eluent;
    modelInfo?: ModelInfo[]
    solventData?: ISolventData
}

function HistoryRowExpansion(props: IHistoryRowExpansionProps): JSX.Element {
    const l10n = useLocalization().history

    const eluentDataLabelStyle = 'text-c1 text-primary-800 text-right w-1/2'
    const eluentDataValueStyle = 'text-c1 text-textDark text-left w-1/2'

    function readableEsiModeValue(): string {
        switch (props.eluentData.esiMode) {
            case 'positive':
                return l10n.expansion.esiModeTranslations.positive

            case 'negative':
                return l10n.expansion.esiModeTranslations.negative

            case 'dual':
                return l10n.expansion.esiModeTranslations.dual
        }
    }

    function readableOrganicPhaseModifierValue(): string {
        const modifier = props.solventData?.organicPhaseModifiers.find(modifier => modifier.value === props.eluentData.organicPhaseModifierValue)
        return (modifier === undefined) ? '' : modifier.readableName
    }

    function readableWaterPhaseAdditiveValue(): string {
        const additive = props.solventData?.waterPhaseAdditives.find(modifier => modifier.value === props.eluentData.waterPhaseAdditiveValue)
        return (additive === undefined) ? '' : additive.readableName
    }

    function readableConcentrationUnitValue(): string {
        const concentration = props.solventData?.concentrationUnits.find(modifier => modifier.value === props.eluentData.concentrationUnitValue)
        return (concentration === undefined) ? '' : concentration.readableName
    }

    function readableModelInfo(): string {
        const displayNameString = props.modelInfo?.map(model => model.displayName).join(", ")
        return displayNameString ?? ''
    }

    function capitalize(string: string): string {
        if (string === undefined || string.length === 0) { return string }
        return string.charAt(0).toUpperCase() + string.slice(1).toLocaleLowerCase()
    }

    return (
        <tr>
            <td colSpan={6} className="border-b border-division">
                <div className="flex flex-col w-full min-h-48 py-4">
                    <div className="flex my-auto justify-between mx-36">
                        <div className="flex-grow">
                            <table className="w-fit">
                                <thead>
                                <tr>
                                    <th colSpan={2} className="text-c1 text-textDark-100 font-semibold text-center">
                                        {props.eluentData.name ?? l10n.table.customConditions}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr className="whitespace-nowrap">
                                    <td className={eluentDataLabelStyle}>{l10n.expansion.esiMode}</td>
                                    <td className={eluentDataValueStyle}>{capitalize(readableEsiModeValue())}</td>
                                </tr>
                                <tr className="whitespace-nowrap">
                                    <td className={eluentDataLabelStyle}>{l10n.expansion.organicPhase}</td>
                                    <td className={eluentDataValueStyle}>{readableOrganicPhaseModifierValue()}</td>
                                </tr>
                                <tr className="whitespace-nowrap">
                                    <td className={eluentDataLabelStyle}>{l10n.expansion.waterPhaseAdditive}</td>
                                    <td className={eluentDataValueStyle}>{readableWaterPhaseAdditiveValue()}</td>
                                </tr>
                                <tr className="whitespace-nowrap">
                                    <td className={eluentDataLabelStyle}>{l10n.expansion.concentrationUnit}</td>
                                    <td className={eluentDataValueStyle}>{readableConcentrationUnitValue()}</td>
                                </tr>
                                <tr className="whitespace-nowrap">
                                    <td className={eluentDataLabelStyle}>{l10n.expansion.pH}</td>
                                    <td className={eluentDataValueStyle}>{props.eluentData.ph}</td>
                                </tr>
                                <tr className="whitespace-nowrap">
                                    <td className={eluentDataLabelStyle}>
                                        <span>{l10n.expansion.deadTime} </span>
                                        <span className="italic">{l10n.expansion.deadTimeT0}</span>
                                    </td>
                                    <td className={eluentDataValueStyle}>{props.eluentData.deadTime}</td>
                                </tr>
                                <tr className="whitespace-nowrap">
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr className="whitespace-nowrap">
                                    <td className={eluentDataLabelStyle}>{l10n.expansion.modelVersion}</td>
                                    <td className={eluentDataValueStyle}>{readableModelInfo()}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="w-64">
                            <table className="breakdown-table">
                                <thead className="text-c1 text-textDark-100 font-bold">
                                <tr>
                                <th className="px-2 text-center">{l10n.expansion.time}</th>
                                    <th className="px-2 text-center">{l10n.expansion.water}</th>
                                    <th className="px-2 text-center">{l10n.expansion.organic}</th>
                                </tr>
                                </thead>
                                <tbody className="text-c1 text-textDark-100">
                                {props.eluentData.steps.map((step, index) => {
                                    return (
                                        <tr key={index} className="hover:bg-hover">
                                            <td className="px-2 text-center">{step.startTime}</td>
                                            <td className="px-2 text-center">{100 - step.organicPhaseModifierPercent}</td>
                                            <td className="px-2 text-center">{step.organicPhaseModifierPercent}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    )
}

export default HistoryRowExpansion
